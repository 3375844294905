import { Component, OnInit } from '@angular/core';
import { CultureService } from '@core/culture.service';
import { TranslocoService } from '@ngneat/transloco';
import { AVAILABLE_LANGS, LANGDEF } from 'app/app.constants';
import { BehaviorSubject } from 'rxjs';

import { ShellService } from '../shell.service';
import { TokenService } from '../token.service';

@Component({
  selector: 'vecmap-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public version: string;
  public buildDate: Date;
  public availableLanguages: LANGDEF[] = AVAILABLE_LANGS;
  public currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  readonly supportUrl = "https://aviagis.atlassian.net/servicedesk/customer/portals";

  constructor(
    public tokenService: TokenService,
    private _shellService: ShellService,
    private _langService: TranslocoService,
    private _cultureService: CultureService) { }

  ngOnInit() {
    this.version = this._shellService.loadVersion();
    this.buildDate = this._shellService.loadBuildDate();

    this._langService.langChanges$.subscribe(
      (activeLanguage) => {
        const currentLanguageLabel = this.availableLanguages.find(lang => lang.key === activeLanguage).label;
        this.currentLanguage.next(currentLanguageLabel);
      });
  }

  switchLanguage(language: string) {
    this._cultureService.changeLanguage(language);
  }
}
