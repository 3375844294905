import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { Project } from '@models';
import { NgxFaviconService } from 'ngx-favicon';
import { interval } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';

import { BreadCrumb } from '../models/breadcrumb';
import { ShellService } from './core/shell.service';
import { SidebarService } from './core/sidebar.service';
import { ProductFavicon } from './favicons.config';
import { LoadingService } from './loading.service';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';

@Component({
  selector: 'vega-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @HostBinding('class') componentCssClass;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _shellService: ShellService,
    private _sidebarService: SidebarService,
    private _overlayContainer: OverlayContainer,
    @Inject('PRODUCT') private _productName: string,
    private ngxFaviconService: NgxFaviconService<ProductFavicon>,
    private _swUpdate: SwUpdate,
    private _dialog: MatDialog,
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
    if (this._swUpdate.isEnabled) {
      // Run check every hour
      const updateChecker = interval(3600000).subscribe(() => {
        this._swUpdate.checkForUpdate();
      });

      this._swUpdate.available.subscribe((event: UpdateAvailableEvent) => {
        updateChecker.unsubscribe();
        this._dialog.open(UpdateDialogComponent,
          {
            disableClose: true,
            maxWidth: '650px'
          })
          .afterClosed().subscribe(result => {
            if (result) {
              window.location.reload();
            }
          });
      });
    }

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(eventData => {
        if (eventData.project) {
          let project: Project = eventData.project;
          if (project) {
            let root = project.levels.find(x => x.root);
            if (!root) {
              root = project.levels[0];
            }

            this._sidebarService.setSidebarRootLevel(root);
          }
        }

        if (eventData.sidebar) {
          this._sidebarService.setSidebarType(eventData.sidebar);
        }

        if (!eventData.skiptitle) {
          this._shellService.setPageTitle(eventData.title);
        }
      });

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(event => this._buildBreadCrumb(this._activatedRoute.root))
      )
      .subscribe(result => {
        this._shellService.breadcrumbs = result;
      });

    if (this._productName === 'SmartSenz') {
      this._overlayContainer.getContainerElement().classList.add('smartsenz');
      this.componentCssClass = 'smartsenz';
    }

    this.ngxFaviconService.setCustomFavicon(this._productName.toLowerCase() as ProductFavicon);
  }

  private _buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<BreadCrumb> = []
  ): Array<BreadCrumb> {
    let crumbsToUse = [...breadcrumbs];

    let params = {};
    route.params.subscribe(result => (params = result));

    let nextUrl = `${url}`;
    if (route.routeConfig && route.routeConfig.path) {
      if (route.routeConfig.path.startsWith(':')) {
        nextUrl += `/${params[route.routeConfig.path.substring(1)]}`;
      } else {
        nextUrl += `/${route.routeConfig.path}`;
      }
    }

    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
      let label = '';
      if (typeof route.routeConfig.data['breadcrumb'] === 'function') {
        route.routeConfig.data['breadcrumb'](route).subscribe(result => {
          label = result;
        });
      } else {
        label = route.routeConfig.data['breadcrumb'];
      }

      const breadcrumb: BreadCrumb = {
        label: label,
        translate: label.startsWith('breadcrumb_'),
        path: nextUrl
      };

      //TODO: Set selected level label in breadcrumb
      if (
        route.routeConfig.data['breadcrumb_params'] ||
        (crumbsToUse.length !== 0 && crumbsToUse[crumbsToUse.length - 1].params)
      ) {
        let qParams = {};
        route.queryParams.subscribe(result => (qParams = result));
        breadcrumb.params = qParams;
      }

      crumbsToUse.push(breadcrumb);
    }

    if (route.firstChild) {
      return this._buildBreadCrumb(route.firstChild, nextUrl, crumbsToUse);
    }
    return crumbsToUse;
  }
}
