<ng-container *transloco="let t; read:'shared'">
  <mat-toolbar class="actionbar">
    <button mat-button (click)="back()">
      <mat-icon svgIcon="arrow-left"></mat-icon>
      <div class="button-text">{{ t('actionbar_return') }}</div>
    </button>

    <div class="v-divider"></div>

    <button mat-button (click)="refreshAction()" [disabled]="refreshDisabled">
      <mat-icon svgIcon="refresh"></mat-icon>
      <div class="button-text">{{t('actionbar_refresh')}}</div>
    </button>

    <div class="v-divider"></div>

    <button mat-button (click)="addAction()" [disabled]="addDisabled">
      <mat-icon svgIcon="plus-circle"></mat-icon>
      <div class="button-text">{{t('actionbar_add')}}</div>
    </button>

    <button mat-button (click)="deleteAction()" [disabled]="deleteDisabled">
      <mat-icon svgIcon="delete"></mat-icon>
      <div class="button-text">{{t('actionbar_delete')}}</div>
    </button>

    <button mat-button (click)="saveAction()" [disabled]="saveDisabled">
      <mat-icon svgIcon="content-save"></mat-icon>
      <div class="button-text">{{t('actionbar_save')}}</div>
    </button>

    <button mat-button (click)="editAction()" [disabled]="editDisabled">
      <mat-icon svgIcon="pencil"></mat-icon>
      <div class="button-text">{{t('actionbar_edit')}}</div>
    </button>

    <div class="v-divider"></div>

    <button mat-button *ngFor="let action of customActions" (click)="customActionClick(action.text)"
      [disabled]="disabledActions[action.text]">
      <mat-icon [svgIcon]="action.icon"></mat-icon>
      <div class="button-text">{{ action.fixed ? action.text : t(action.text) }}</div>
    </button>
  </mat-toolbar>
</ng-container>
