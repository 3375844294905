import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '../../auth.service';
import { ShellService } from '../shell.service';
import { TokenService } from '../token.service';

@Component({
  selector: 'vecmap-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public pageTitle: BehaviorSubject<string>;

  constructor(
    private _authService: AuthService,
    public shellService: ShellService,
    public tokenService: TokenService,
    private _router: Router,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.pageTitle = this.shellService.pageTitle;
  }

  logout() {
    this._authService.logout();
  }

  returnAdmin() {
    this.tokenService.closeProject();
    this._router.navigate(['/admin']);
  }
}
