<ng-container *transloco="let t; read: 'updateDialog'">
  <h2 mat-dialog-title> {{t('update_new_version_web')}}</h2>
  <mat-dialog-content>
    <p>
      {{t('update_info_reload')}}
    </p>
    <p>
      <b>
        {{t('update_old_version_warning')}}
      </b>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>
      {{t('update_later')}}
    </button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>{{t('update_reload')}}</button>
  </mat-dialog-actions>
</ng-container>
