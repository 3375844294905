import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Project } from '@models';
import { SpeciesService } from '../species.service';
import { TokenService } from '@core/token.service';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'vecmap-project-settings-form',
  templateUrl: './project-settings-form.component.html',
  styleUrls: ['./project-settings-form.component.css']
})
export class ProjectSettingsFormComponent implements OnInit {
  @Input()
  project: Project;

  @Input()
  projectSettingsForm: FormGroup;

  /**
   * t(shared.Enabled)
   * t(shared.Active)
   * t(shared.Completed)
   * t(shared.Archived)
   */
  projectStatus = ['Enabled', 'Active', 'Completed', 'Archived'];


  speciesCategories: Array<any>;

  constructor(private _speciesService: SpeciesService, private _tokenService: TokenService, private fb: FormBuilder) {
    this.loadSpeciesCategories();
  }

  ngOnInit() {
    this.projectSettingsForm.addControl('project_type', new FormControl('transversal', Validators.required));
    this.projectSettingsForm.addControl('status', new FormControl('Enabled', Validators.required));
    this.projectSettingsForm.addControl('name', new FormControl(translate('untitled_project'), Validators.required));
    this.projectSettingsForm.addControl('projection', new FormControl('WGS84', Validators.required));
    this.projectSettingsForm.addControl('species_categories', new FormControl([]));
    this.projectSettingsForm.addControl('modelboost', new FormControl(false));
    this.projectSettingsForm.addControl('allow_lab_record_access', new FormControl(false));
    this.projectSettingsForm.addControl('use_lab_record_name', new FormControl(false));

    if (this.superUser()) {
      this.projectSettingsForm.addControl('submit_only', new FormControl());
    }

    if (this.project) {
      this.projectSettingsForm.patchValue(this.project);
    }
  }

  loadSpeciesCategories() {
    this._speciesService.loadSpeciesCategories().subscribe(speciescats => (this.speciesCategories = speciescats));
  }

  superUser() {
    return this._tokenService.isSuperUser();
  }
}
