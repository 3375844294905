import { CdkTableModule } from '@angular/cdk/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AviaErrorHandlerModule, Configuration } from '@aviagis-frontend/error-handler';
import { environment } from '@environment';
import { SharedModule } from '@shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { NgxFaviconModule } from 'ngx-favicon';
import { ToastrModule } from 'ngx-toastr';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { ConsentModule } from './consent/consent.module';
import { CoreModule } from './core/core.module';
import { DeviceIdInterceptor } from './device-id.interceptor';
import { DeviceIdService } from './device-id.service';
import { ErrorInterceptor } from './errror.interceptor';
import { AvailableProductFavicons, productFavicons } from './favicons.config';
import { HomeComponent } from './home/home.component';
import { NewProjectComponent } from './new-project/new-project.component';
import { NewProjectService } from './new-project/new-project.service';
import { ProductNameInterceptor } from './product-name.interceptor';
import { QueryInterceptor } from './query.interceptor';
import { StaffService } from './staff/staff.service';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';
import { initializeApplication, isProductionMode, productNameFactory } from './utities.config';
import { VersionInterceptor } from './version.interceptor';
import { LoadingService } from './loading.service';

@NgModule({
  declarations: [AppComponent, HomeComponent, NewProjectComponent, UpdateDialogComponent],
  imports: [
    BrowserModule,

    TranslocoMessageFormatModule.init(),

    HttpClientModule,
    CdkTableModule,

    BrowserModule,
    BrowserAnimationsModule,

    AppRoutingModule,

    // Own modules
    SharedModule,
    CoreModule,

    ToastrModule.forRoot(),

    MatSidenavModule,
    // TODO: Disable error tracking for backend errors
    AviaErrorHandlerModule.forRoot({
      environment: environment.env,
      release: environment.version,
      sentry: {
        ssl: true,
        key: '384967350cab403cbb63795b87d8798a',
        project_id: 2
      },
      enabled: isProductionMode()
    } as Configuration),
    ConsentModule,

    NgxFaviconModule.forRoot<AvailableProductFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: productFavicons
    }),

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: isProductionMode(), registrationStrategy: 'registerImmediately' }),
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [UpdateDialogComponent],
  providers: [
    DeviceIdService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [DeviceIdService],
      multi: true
    },
    NewProjectService,
    StaffService,
    AuthService,

    LoadingService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProductNameInterceptor,
      multi: true
    },
    {
      provide: 'PRODUCT',
      useFactory: productNameFactory
    },
    {
      provide: 'ENVIRONMENT',
      useValue: environment.env
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer) {
    this._matIconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
