import { Injectable } from "@angular/core";
import { TokenService } from "@core/token.service";
import { Socket } from "ngx-socket-io";

@Injectable()
export class ApiWebsocket extends Socket {
  constructor(private _tokenService: TokenService) {
    super({
      url: '',
      options: {
        transports: ['websocket'],
        path: '/api/websocket',
        auth: {
          token: _tokenService.token
        },
      }
    });
  }
}
