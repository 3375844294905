<ng-container *transloco="let t; read: 'shell'">
  <mat-toolbar color="primary">
    <div class="footer-group" *ngIf="tokenService.token">
      <span class="mat-body-1">
        <b>{{t('footer_user')}}:</b> {{ tokenService.tokenInfo?.user_name }}
      </span>
      <span class="footer-spacer"></span>
      <span class="mat-body-1">
        <b>{{t('footer_workspace')}}:</b> {{ tokenService.tokenInfo?.customer_name }}
      </span>
      <span class="footer-spacer"></span>
      <span class="mat-body-1" [hidden]="!tokenService.tokenInfo?.project_name">
        <b>{{t('footer_project')}}:</b> {{ tokenService.tokenInfo?.project_name }}
      </span>
    </div>
    <span class="footer-spacer"></span>

    <a mat-button [href]="supportUrl" target="_blank">
      <mat-icon svgIcon="forum-outline" class="footer-icon"></mat-icon> {{t('footer_support_button')}}
    </a>

    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="web" class="footer-icon"></mat-icon>&nbsp;{{ currentLanguage | async }}
    </button>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item [disabled]="!tokenService.token" (click)="switchLanguage('workspace')">
          {{ t('languages.workspace') }}
        </button>
        <button mat-menu-item (click)="switchLanguage('device')">
          {{ t('languages.device') }}
        </button>
        <button *ngFor="let lang of availableLanguages" mat-menu-item
          (click)="switchLanguage(lang.key)">{{ lang.label }}</button>
      </ng-template>
    </mat-menu>
    <div class="v-divider"></div>
    <span class="mat-caption" matTooltipPosition="left" [matTooltip]="'Build on ' + (buildDate | dateFormat)">
      {{t('footer_version')}} {{ version }}
    </span>
  </mat-toolbar>
</ng-container>
