import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './core/token.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private _router: Router, private _tokenService: TokenService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this._tokenService.getExistingToken();

    if (!this._tokenService.tokenValid()) {
      this._tokenService.logout(); // force logout to make sure the invalid token is cleared
      this._router.navigate(['/login']);
      return false;
    }

    return true;
  }
}
