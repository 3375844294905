export const defaultPageTitle = 'Admin';

export interface LANGDEF {
  key: string;
  label: string;
}

export const AVAILABLE_LANGS: LANGDEF[] = [
  {
    key: 'en',
    label: 'English'
  }, {
    key: 'fr',
    label: 'Français'
  }, {
    key: 'es',
    label: 'Español'
  }
];
