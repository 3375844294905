import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { v4 } from 'uuid';

const COOKIE_NAME = 'VECMAP-Device_id';

@Injectable()
export class DeviceIdService {
  private _deviceId: string;

  constructor(private _cookieService: CookieService) {}

  init() {
    return new Promise<void>(resolve => {
      if (!this._cookieService.check(COOKIE_NAME)) {
        this._cookieService.set(COOKIE_NAME, v4());
      }

      this._deviceId = this._cookieService.get(COOKIE_NAME);

      resolve();
    });
  }

  get deviceId(): string {
    return this._deviceId;
  }
}
