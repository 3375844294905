import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vecmap-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  public childAction: 'delete_all' | 'delete' | 'orphan';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  closeDialog() {
    let dialogResult = this.data.children
      ? {
          childAction: this.childAction
        }
      : true;
    this.dialogRef.close(dialogResult);
  }

  public get isPlural() {
    return this.data.multiple && this.data.multiple > 1;
  }
}
