import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';

import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { TokenService } from '@core/token.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _toastService: ToastrService, private _router: Router, private _tokenService: TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse && (response.status >= 500 || req.url !== 'api/user/login')) {
          if (response.status >= 500) {
            // Set title to undefined for now, TODO: Replace with tracking ID once sent back from the server
            this._toastService.error(translate('internal_server_error_toastr'), undefined, {
              disableTimeOut: true,
              closeButton: true
            });
          } else if (response.error && response.error.name === 'TokenExpiredError') {
            this._toastService.error(translate('login_session_expired_toastr'));
            this._tokenService.logout();
            this._router.navigate(['login']);
          } else if (response.error && response.error.message) {
            this._toastService.error(response.error.message);
          } else {
            // Generic request failure, likely due to bad data/query send to server. TODO: Check if needed to be tracked in Sentry as well
            this._toastService.error(translate('request_failure_toastr'));
          }
        }

        return throwError(response);
      })
    );
  }
}
