import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './core/token.service';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router, private _tokenService: TokenService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this._tokenService.isAdmin()) {
      this._router.navigate(['/project/open']);
    }
    return this._tokenService.isAdmin();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this._tokenService.isAdmin()) {
      this._router.navigate(['/project/open']);
    }
    return this._tokenService.isAdmin();
  }
}
