import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Level } from '@models';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class SidebarService implements OnInit {
  public sidebarType = new BehaviorSubject<'lab' | 'admin' | 'project' | 'open' | ''>('');
  public workspaceConfig = new BehaviorSubject<boolean>(false);
  public projectConfig = new BehaviorSubject<boolean>(false);

  isAdmin = false;

  rootLevel: Level;

  constructor(private _http: HttpClient, private _tokenService: TokenService) {}

  ngOnInit() {
    this.isAdmin = this._tokenService.isAdmin();
  }

  setSidebarType(type: 'lab' | 'admin' | 'project' | 'open' | '') {
    this.sidebarType.next(type);
  }

  setSidebarMenu(sidebarConfig) {
    this.workspaceConfig.next(sidebarConfig.workspace);
    this.projectConfig.next(sidebarConfig.project);
  }

  setSidebarRootLevel(rootLevel: Level) {
    this.rootLevel = rootLevel;
  }

  deleteProject(): Observable<void> {
    return this._http.delete<void>(`/api/project/${this._tokenService.tokenInfo.project_id}`);
  }
}
