import { environment } from '@environment';

import { DeviceIdService } from './device-id.service';

export function initializeApplication(_fingerprintService: DeviceIdService) {
  return (): Promise<any> => {
    return _fingerprintService.init();
  };
}

export function productNameFactory() {
  let productName = 'VECMAP';
  const hostname = window.location.hostname;
  if (hostname.endsWith('smartsenz.com')) {
    productName = 'SmartSenz';
  }

  return productName;
}

export function isProductionMode() {
  return ['Production', 'Trial', 'Dev'].indexOf(environment.env) !== -1;
}
