

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';
import { AppModule } from './app/app.module';


// Set both production and staging environments to ProdMode, ensures we can use isDevMode in error handler to register Sentry
if (['Production', 'Trial', 'Dev'].indexOf(environment.env) !== -1) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
