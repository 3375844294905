import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionButton } from '@models';

@Component({
  selector: 'vecmap-actionbar',
  templateUrl: './actionbar.component.html',
  styleUrls: ['./actionbar.component.css']
})
export class ActionbarComponent implements OnInit {
  @Output() onSave = new EventEmitter<void>();
  @Output() onAdd = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() onRefresh = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<void>();

  @Output() onBack = new EventEmitter<void>();

  @Input() addDisabled = true;
  @Input() deleteDisabled = true;
  @Input() saveDisabled = true;
  @Input() refreshDisabled = true;
  @Input() editDisabled = true;

  @Input() customActions: ActionButton[];
  @Output() customActionEvent = new EventEmitter<string>();
  @Input() disabledActions: {} = {};

  constructor(private _location: Location) {}

  ngOnInit() {}

  saveAction() {
    this.onSave.emit();
  }

  addAction() {
    this.onAdd.emit();
  }

  deleteAction() {
    this.onDelete.emit();
  }

  refreshAction() {
    this.onRefresh.emit();
  }

  editAction() {
    this.onEdit.emit();
  }

  back() {
    if (this.onBack.observers.length) {
      this.onBack.emit();
    } else {
      this._location.back();
    }
  }

  customActionClick(action: string): void {
    this.customActionEvent.emit(action);
  }
}
