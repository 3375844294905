import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class SpeciesService {
  private _speciesCategoriesUrl = 'api/species-category';

  constructor(private _http: HttpClient) {}

  loadSpeciesCategories(): Observable<any> {
    return this._http.get(`${this._speciesCategoriesUrl}`);
  }
}
