import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from './core/token.service';

@Injectable()
export class QueryInterceptor implements HttpInterceptor {
  constructor(private _tokenService: TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let params: HttpParams = req.params;
    // console.debug(`Query incoming: ${req.urlWithParams}`);

    let headers: HttpHeaders = req.headers;

    if (this._tokenService.token) {
      headers = headers.set('Authorization', `Bearer ${this._tokenService.token}`);
    }

    const duplicate = req.clone({
      params: params,
      headers: headers
    });
    return next.handle(duplicate);
  }
}
