import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  _loadingSubject$: Subject<boolean> = new BehaviorSubject(false);

  showLoadingOverlay() {
    this._loadingSubject$.next(true);
  }

  hideLoadingOverlay() {
    this._loadingSubject$.next(false);
  }

  get shouldShowLoadingOverlay() {return this._loadingSubject$; }
}
