import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PermissionService } from '@core/permissions/permission.service';
import { ConfirmDialogComponent } from '@shared/dialog/confirm-dialog.component';

import { SidebarService } from '../sidebar.service';
import { TokenService } from '../token.service';

@Component({
  selector: 'vecmap-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  @Output()
  route = new EventEmitter();

  collapsed = false;

  routes = [
    {
      label: 'project_configuration',
      items: [
        {
          label: 'project_home',
          url: '',
          exact: true,
          icon: 'folder-home'
        },
        {
          label: 'project_settings',
          url: 'edit',
          icon: 'wrench' // TODO: folder-wrench is better, but only from MDI 6.6
        },
        {
          label: 'project_structure',
          url: 'structure',
          icon: 'format-list-group'
        },
        {
          label: 'layouts',
          url: 'layout',
          icon: 'view-dashboard'
        }
      ]
    },
    {
      label: 'project_staff',
      items: [
        {
          label: 'staff_management',
          url: 'staff',
          icon: 'account' // TODO: folder-user is better, but only from MDI 6.6
        },
        {
          label: 'teams',
          url: 'team',
          icon: 'account-multiple'
        }
      ]
    },
    {
      label: 'sampling_management',
      items: [
        {
          label: 'data_management',
          url: 'records',
          icon: 'format-list-text',
          params: () => {
            return {
              level_id: this.sidebarService.rootLevel.level_id
            };
          }
        },
        {
          label: 'assignments',
          url: 'assignment',
          icon: 'calendar'
        }
      ]
    }
  ];

  constructor(
    public sidebarService: SidebarService,
    private _dialog: MatDialog,
    public tokenService: TokenService,
    private _router: Router,
    private _permissionsService: PermissionService,
    @Inject('PRODUCT') private _productName: string
  ) { }

  deleteProject() {
    this._dialog
      .open(ConfirmDialogComponent, {
        data: {
          type: 'project'
        }
      })
      .afterClosed()
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.sidebarService.deleteProject().subscribe(result => {
            this.tokenService.closeProject();
            this._router.navigate(['/admin/open']);
          });
        }
      });
  }

  collapse() {
    this.collapsed = !this.collapsed;
  }

  closeProject() {
    this.tokenService.closeProject();
    this._router.navigate(['/admin/open']);
  }

  returnAdmin() {
    this.tokenService.closeProject();
    this._router.navigate(['/admin']);
  }

  isRouteActive(path: string, exact: boolean = false, project: boolean = true) {
    let urlTree = project ? this._generateUrlTree(path) : [path];
    return this._router.isActive(this._router.createUrlTree(urlTree), exact);
  }

  navigateTo(path: string, params) {
    let queryParams = {};
    if (params) {
      queryParams = params();
    }
    this._router.navigate(this._generateUrlTree(path), { queryParams });
  }

  canManageProject() {
    return this._permissionsService.canManageProject();
  }

  canAccessLab() {
    return this._permissionsService.canAccessLab();
  }

  canAccessProgressReport() {
    return this._permissionsService.canViewAllLabRecords();
  }

  canAccessExport() {
    return this.canManageProject() || this.canAccessLab();
  }

  noProjectSelected() {
    return !this.tokenService.tokenInfo?.project_id;
  }

  isSmartSenz() {
    return this._productName === 'SmartSenz';
  }

  private _generateUrlTree(path) {
    let routes = ['/project', this.tokenService.tokenInfo.project_id];
    if (path) {
      path.split('/').forEach(element => {
        routes.push(element);
      });
    }
    return routes;
  }
}
