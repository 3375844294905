import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './admin.guard';
import { HomeComponent } from './home/home.component';
import { LoginGuard } from './login.guard';
import { NewProjectComponent } from './new-project/new-project.component';
import { ProductGuard } from './product.guard';

const routes: Routes = [
  {

    // Login Route
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: {
      title: 'Login'
    }
  },
  {
    //TODO  fallback
    // Fallback route
    path: '',
    component: HomeComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        redirectTo: '/admin',
         pathMatch: 'full'
      },
      {
        path: 'admin',
        children: [
          {
            path: '',
            loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule),
            data: {
              title: 'Workspace information'
            }
          },
          {
            path: 'configure',
            loadChildren: () =>
              import('./workspace-configuration/workspace-configuration.module').then(
                m => m.WorkspaceConfigurationModule
              ),
            data: {
              title: 'Workspace Configuration',
              breadcrumb: 'breadcrumb_configure'
            }
          },
          {
            path: 'staff',
            loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
            data: {
              title: 'Staff configuration',
              breadcrumb: 'breadcrumb_staff'
            }
          },
          // {
          //   path: 'conflict',
          //   loadChildren: './conflict/conflict.module#ConflictModule'
          // },
          {
            path: 'new',
            component: NewProjectComponent,
            data: {
              title: 'New Project',
              breadcrumb: 'breadcrumb_project_new'
            }
          },
          {
            path: 'open',
            loadChildren: () => import('./open-project/open-project.module').then(m => m.OpenProjectsModule),
            data: {
              title: 'Open Project'
            }
          },
          {
            path: 'license',
            loadChildren: () => import('./license/license.module').then(m => m.LicenseModule),
            data: {
              title: 'Cloud Dongles'
            }
          }
        ],
        data: {
          sidebar: 'admin'
        },
        canActivate: [AdminGuard],
        canActivateChild: [AdminGuard]
      },
      {
        // Project router
        path: 'project',
        loadChildren: () => import('./project/project.module').then(m => m.ProjectsModule),
        data: {
          sidebar: 'project'
        },
      }
    ]
  },
  {
    // Catch all
    path: '**',
    redirectTo: 'admin'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [LoginGuard, AdminGuard, ProductGuard]
})
export class AppRoutingModule { }
