import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { SearchCriteria } from '@aviagis-frontend/components';
import { PagerMetadata } from '@aviagis-frontend/pager';
import { Count, User } from '@models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class StaffService {
  private _staffUrl = 'api/staff';

  constructor(private _http: HttpClient) { }

  loadWorkspaceStaff(page = 0, limit = 10): Observable<User[]> {
    const params = new HttpParams().set('page', page.toString()).set('limit', limit.toString());
    return this._http.get<User[]>(this._staffUrl, { params });
  }

  countWorkspaceStaff(): Observable<Count> {
    return this._http.get<Count>(`${this._staffUrl}/count`);
  }

  updateStaff(staffId, staff): Observable<User> {
    return this._http.put<User>(`${this._staffUrl}/${staffId}`, staff);
  }

  createStaff(staff: User): Observable<User> {
    return this._http.post<User>(this._staffUrl, staff);
  }

  deleteStaff(staffId: string): Observable<void> {
    return this._http.delete<void>(`${this._staffUrl}/${staffId}`);
  }

  deleteMultipleStaff(staffIds: string[]): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: staffIds
    };

    return this._http.delete<void>(`${this._staffUrl}/bulk`, options);
  }

  getStaff(staffId: string): Observable<User> {
    return this._http.get<User>(`${this._staffUrl}/${staffId}`);
  }

  checkUniqueLogin(login: string): Observable<Boolean> {
    const params = new HttpParams().set('login', login);
    return this._http.get<Boolean>('api/check/login', { params });
  }





  // Data table loading
  loadPagingInfo(searchCriteria?: SearchCriteria) {
    return this._http.get<Count>(`${this._staffUrl}/count`).pipe(
      map(count => {
        return { total: count.count };
      })
    );
  }

  loadData(sort?: Sort, page?: PagerMetadata, searchCriteria?: SearchCriteria) {
    const params = new HttpParams()
      .set('page', page.currentPage.toString())
      .set('limit', page.pageSize.toString());
    return this._http.get<User[]>(this._staffUrl, { params });
  }
}
