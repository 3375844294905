import { Injectable } from '@angular/core';
import { TokenService } from '@core/token.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(private _tokenService: TokenService) { }

  canAccessLab() {
    return this._tokenService.isAllowed('view_lab_records', ['All', 'Own']) ||
      this.canCreateLabRecords() ||
      this._tokenService.isAllowed('modify_lab_records');
  }

  canManageProject() {
    return this._tokenService.isAllowed('manage_project');
  }

  canViewAllLabRecords() {
    return this._tokenService.isAllowed('view_lab_records', ['All']);
  }

  hasAccessToRecordMetadata() {
    return this._tokenService.isAllowed('view_record_form_data', ['Full', 'Metadata']);
  }

  hasAccessToRecordFormData() {
    return this._tokenService.isAllowed('view_record_form_data', ['Full']);
  }

  canModifyLabRecords() {
    return this._tokenService.isAllowed('modify_lab_records');
  }

  canCreateLabRecords() {
    return this._tokenService.isAllowed('create_lab_records', ['List', 'Code']);
  }

  canAccessLabListing() {
    return this._tokenService.isAllowed('create_lab_records', ['List']) ||
      (!this.canCreateLabRecords() &&
        this._tokenService.isAllowed('view_lab_records', ['All', 'Own']));
  }

  canAccessLabDocument(user_id: string) {
    return this.canViewAllLabRecords() ||
      (this._tokenService.isAllowed('view_lab_records', ['Own']) && user_id === this._tokenService.tokenInfo.user_id);
  }

  isOwnerOfDatafile(user_id: string) {
    return this._tokenService.tokenInfo.user_id === user_id;
  }

  canUploadDatafile() {
    return this._tokenService.isAllowed('upload_datafile');
  }

  canViewDatafile(datafile) {
    return datafile.created_by.user_id === this._tokenService.tokenInfo.user_id ||
      datafile.access.some(permission => permission.actor_id === this._tokenService.tokenInfo.user_id && permission.permission === 'View');
  }

  canDeleteDatafile(datafile) {
    return datafile.created_by.user_id === this._tokenService.tokenInfo.user_id ||
      datafile.access.some(permission => permission.actor_id === this._tokenService.tokenInfo.user_id && permission.permission === 'Delete');
  }

  canDeleteMultipleDatafiles(datafiles) {
    var file = datafiles.every(datafile => this.canDeleteDatafile(datafile));
    return file
  }
}
