import { Injectable } from '@angular/core';
import { Layout } from '@aviagis-frontend/dynamic-forms/lib/dynamic-form-viewer/models/layout';
import { Level, Project } from '@models';

@Injectable()
export class GeneralService {
  private _projectStructure: Level[];
  private _project: Project;
  private _layouts: Layout[];

  get structure(): Level[] {
    return this._projectStructure || [];
  }

  set structure(value: Level[]) {
    this._projectStructure = value;
  }

  get project(): Project {
    return this._project;
  }

  set project(projectDoc: Project) {
    this._project = projectDoc;
  }

  get layouts(): Layout[] {
    return this._layouts || [];
  }

  set layouts(value: Layout[]) {
    this._layouts = value;
  }

  updateCachedLayout(layout: Layout) {
    const oldLayouts = this.layouts;
    const currentLayoutIndex = oldLayouts.findIndex(layout => layout.layout_id === layout.layout_id);
    oldLayouts[currentLayoutIndex] = layout;
    this.layouts = oldLayouts;
  }
}
