
<ng-container *transloco="let t; read:'shared'">
    <mat-card>
      <form novalidate [formGroup]="projectSettingsForm" fxLayout="column">
        <mat-form-field *ngIf="false" fxFlex>
          <mat-select placeholder="Project Type" formControlName="project_type" class="form-row">
            <mat-option [value]="'transversal'">{{t('settings_transversal')}}</mat-option>
            <mat-option [value]="'longitudinal'">{{t('settings_longitudinal')}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div>
          <mat-form-field fxFlex>
            <mat-select placeholder="{{t('settings_status_placeholder')}}" formControlName="status" class="form-row">
              <mat-option *ngFor="let status of projectStatus" [value]="status">{{ t(status) }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field floatPlaceholder="always" class="form-row" fxFlex>
            <input matInput type="text" placeholder="{{t('settings_name_placeholder')}}" formControlName="name" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field floatPlaceholder="always" class="form-row" *ngIf="false" fxFlex>
            <input matInput type="text" placeholder="{{t('settings_projection_placeholder')}}" formControlName="projection" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field fxFlex>
            <mat-select placeholder="{{t('settings_species_categories_placeholder')}}" multiple name="species" formControlName="species_categories">
              <mat-option *ngFor="let speciesCat of speciesCategories" [value]="speciesCat.uid">
                {{ speciesCat.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-checkbox *ngIf="false" formControlName="modelboost" value="true">{{t('settings_enable_modelboost')}}</mat-checkbox>

        <mat-checkbox class="checkbox" formControlName="allow_lab_record_access" value="true">
          {{t('settings_allow_all_record')}}
        </mat-checkbox>

        <mat-checkbox formControlName="use_lab_record_name" value="true">
        {{t('settings_use_record_names')}}
        </mat-checkbox>

        <ng-container *ngIf="superUser()">
          <mat-checkbox formControlName="submit_only" value="true">
            {{t('settings_mark_project')}}
          </mat-checkbox>
        </ng-container>
      </form>
    </mat-card>
</ng-container>
