import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpeciesCategory } from 'models/species_category';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export interface SpeciesFilter {
  genus: string;
  name: string;
  speciescat_id: string;
  uid: string
}

@Injectable()
export class SpeciesSelectionService {
  private _speciesUrl = 'api/species';
  private _speciesCategoryUrl = 'api/species-category';

  private _speciesCategories: Observable<SpeciesCategory[]>;

  constructor(private _http: HttpClient) { }

  getSpeciesCategories() {
    if(!this._speciesCategories) {
      this._speciesCategories = this._http.get<SpeciesCategory[]>(this._speciesCategoryUrl).pipe(
        shareReplay(1)
      );
    }

    return this._speciesCategories;
  }

  loadFilteredSpecies(filterQuery: {
    genus: string,
    name: string
  }): Observable<SpeciesFilter[]> {
    let params = new HttpParams();

    if(filterQuery.genus) {
      params = params.append('genus', filterQuery.genus);
    }

    if(filterQuery.name) {
      params = params.append('name', filterQuery.name);
    }

    return this._http.get<SpeciesFilter[]>(`${this._speciesUrl}/filter`, { params: params });
  }
}
