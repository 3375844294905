export enum ProductFavicon {
  VECMAP_FAVICON = 'vecmap',
  SMARTSENZ_FAVICON = 'smartsenz'
}

export type AvailableProductFavicons = { [key in ProductFavicon]: string };

export const productFavicons: AvailableProductFavicons = {
  vecmap: 'assets/favicon_vecmap.png',
  smartsenz: 'assets/favicon_smartsenz.png'
};
