import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'vecmap-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  constructor(private _router: Router, private _activatedRoute: ActivatedRoute) {}

  goTo(event: { route: string[]; query: {} }) {
    this._router.navigate(event.route, { queryParams: event.query, relativeTo: this._activatedRoute });
  }
}
