import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ErrorTrackingService } from '@aviagis-frontend/error-handler';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared/shared.module';
import { CultureService } from './culture.service';
import { FooterComponent } from './footer/footer.component';
import { GeneralService } from './general.service';
import { HeaderComponent } from './header/header.component';
import { throwIfAlreadyLoaded } from './module-import.guard';
import { PermissionGuard } from './permissions/permission.guard';
import { ShellService } from './shell.service';
import { SidebarService } from './sidebar.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TokenService } from './token.service';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    TranslocoRootModule,
    TranslocoModule,
    MatMenuModule
  ],
  declarations: [FooterComponent, HeaderComponent, SidebarComponent],
  exports: [FooterComponent, HeaderComponent, SidebarComponent],
  providers: [
    ShellService,
    SidebarService,
    GeneralService,
    TokenService,
    CultureService,
    PermissionGuard,
    ErrorTrackingService
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
