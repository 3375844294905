<ng-container *transloco="let t; read:'shared'">
  <ng-container *ngIf="isPlural">
    <h2 mat-dialog-title>{{t('confirm_warning_type_plural', { type: data.type }) }}</h2>
  </ng-container>
  <ng-container *ngIf="!isPlural">
    <h2 mat-dialog-title>{{ t('confirm_warning_type', { type: data.type }) }}</h2>
  </ng-container>
  <mat-dialog-content>
    <ng-container *ngIf="isPlural">
      <span [innerHTML]="t('confirm_delete_dialog_multiple', { count: data.multiple, type: data.type })">
      </span>
    </ng-container>

    <ng-container *ngIf="!isPlural">
      {{ t('confirm_delete_dialog_single', { type: data.type }) }}
    </ng-container>

    <br />
    <ng-container *ngIf="data.children">
      <mat-radio-group class="child-action-group" [(ngModel)]="childAction">
        <mat-radio-button class="child-action-button" value="delete_all">
          {{t('confirm_delete_child_delete_all')}}
        </mat-radio-button>
        <mat-radio-button class="child-action-button" value="orphan">
          {{t('confirm_delete_child_orphan')}}
        </mat-radio-button>
        <mat-radio-button class="child-action-button" value="delete">
          {{t('confirm_delete_child_delete')}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="closeDialog()" [disabled]="data.children && !childAction">
      {{t('delete_button')}}
    </button>
    <button mat-button mat-dialog-close>{{t('cancel_button')}}</button>
  </mat-dialog-actions>
</ng-container>
