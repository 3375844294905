import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';

import { BreadCrumb } from '../../models/breadcrumb';
import { defaultPageTitle } from '../app.constants';

@Injectable()
export class ShellService {
  public pageTitle: BehaviorSubject<string> = new BehaviorSubject<string>(this.constructDefaultTitle());

  public breadcrumbs: BreadCrumb[];

  constructor(private _titleService: Title, @Inject('PRODUCT') private _productName: string) {}

  loadVersion(): string {
    return environment.version;
  }

  constructDefaultTitle() {
    return `${this._productName} - ${defaultPageTitle}`;
  }

  loadBuildDate(): Date {
    let date = new Date(environment.buildDate);

    if (date instanceof Date && !isNaN(date.getTime())) {
      return date;
    } else {
      return new Date();
    }
  }

  setPageTitle(title?: string): void {
    if (!title) {
      title = defaultPageTitle;
    }
    this.pageTitle.next(title);
    this._titleService.setTitle(`${this._productName} - ${title}`);
  }

  setBreadCrumbs(crumbs: BreadCrumb[]) {
    this.breadcrumbs = crumbs;
  }
}
