import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DynamicFormsDataService, FieldConfigurationService } from '@aviagis-frontend/dynamic-forms';
import { ErrorTrackingService } from '@aviagis-frontend/error-handler';
import { Permissions } from '@core/permissions/permissions.interface';
import { ADMINISTRATOR } from '@core/permissions/roles.constant';

import { CultureService } from './culture.service';

interface License {
  start_date: string;
  end_date: string;
  product_name: 'VECMAP' | 'SmartSenz';
  license_type: string;
}

@Injectable()
export class TokenService {
  private _token: string;

  public tokenInfo: {
    customer_id: string;
    customer_name: string;
    user_id: string;
    user_name: string;
    project_id?: string;
    project_name?: string;
    roles: string[];
    permissions?: Permissions;
    licenses: License[];
    superuser: boolean;
    culture: string;
  };

  private _jwtHelper = new JwtHelperService();

  constructor(
    private _dataService: DynamicFormsDataService,
    private _errorContext: ErrorTrackingService,
    private _fieldConfigService: FieldConfigurationService,
    private _cultureService: CultureService
  ) { }

  setNewToken(token: string) {
    localStorage.setItem('authtoken', token);

    this.setOptionsFromToken(token);
  }

  getExistingToken() {
    if(this._token) return;

    let token = localStorage.getItem('authtoken');

    if(!token) return;

    this.setOptionsFromToken(token);
  }

  private setOptionsFromToken(token: string) {
    this._token = token;

    this.tokenInfo = this._jwtHelper.decodeToken(token);

    this._errorContext.setContext(this.tokenInfo);
    this._dataService.currentUser = {
      user_id: this.tokenInfo.user_id,
      user_name: this.tokenInfo.user_name
    };
    this._fieldConfigService.setCurrentProduct(this.tokenInfo.licenses[0].product_name);

    this._cultureService.workspaceCulture.next(this.tokenInfo.culture);
    // TODO: Trigger dialog message when logging in and workspace language is different from current language
  }

  get token() {
    return this._token;
  }

  tokenValid() {
    return this._token && !this._jwtHelper.isTokenExpired(this._token);
  }

  isAdmin() {
    return this.tokenInfo && this.tokenInfo.roles.indexOf(ADMINISTRATOR) !== -1;
  }

  logout() {
    localStorage.removeItem('authtoken');

    delete this._token;
    delete this.tokenInfo;
  }

  closeProject() {
    delete this.tokenInfo.project_id;
    delete this.tokenInfo.project_name;
  }

  hasExpertLicense() {
    return this.tokenInfo.licenses[0].license_type === 'Expert';
  }

  isITM() {
    return this.tokenInfo.customer_id === 'customer:itm';
  }

  isSuperUser() {
    return this.tokenInfo.superuser;
  }

  isAllowed(key: keyof Permissions, value: string[] = ['Yes']) {
    return this.tokenInfo &&
      this.isAdmin() ||
      (this.tokenInfo.permissions &&
        value.indexOf(this.tokenInfo.permissions[key]) > -1);
  }
}
