import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '@core/permissions/permission.service';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private _permissionService: PermissionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles: string[] = route.data.permissions;

    if (!allowedRoles) {
      return true;
    }

    const canAccess = allowedRoles.some(role => {
      switch (role) {
        case 'lab':
          return this._permissionService.canAccessLab();

        case 'lab-progress':
          return this._permissionService.canViewAllLabRecords();

        case 'pm':
          return this._permissionService.canManageProject();

        default:
          return false;
      }
    });

    // TODO: Take action when user is not allowed
    return canAccess;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles: string[] = route.data.permissions;

    if (!allowedRoles) {
      return true;
    }

    const canAccess = allowedRoles.some(role => {
      switch (role) {
        case 'lab':
          return this._permissionService.canAccessLab();

        case 'lab-progress':
          return this._permissionService.canViewAllLabRecords();

        case 'pm':
          return this._permissionService.canManageProject();

        default:
          return false;
      }
    });

    // TODO: Take action when user is not allowed
    return canAccess;
  }
}
