<ng-container *transloco="let t; read: 'shell'" [ngSwitch]="sidebarService.sidebarType | async">
  <div fxLayout="column" fxFlex class="sidebar-container" [ngClass]="{ collapsed: collapsed }">
    <ng-container *ngSwitchCase="'admin'">
      <p class="mat-title menu-title">
        <ng-container *ngIf="!collapsed">{{t('sidebar_general_config')}}</ng-container>
      </p>
      <mat-divider></mat-divider>

      <mat-nav-list>
        <a mat-list-item [class.active]="isRouteActive('admin', true, false)" routerLink="/admin"
          [matTooltip]="t('sidebar_workspace_home')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="home"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_workspace_home')}}
          </ng-container>
        </a>
        <a mat-list-item [class.active]="isRouteActive('admin/staff', false, false)" routerLink="/admin/staff"
          [matTooltip]="t('sidebar_staff')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="account-group"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_staff')}}
          </ng-container>
        </a>
        <a mat-list-item [class.active]="isRouteActive('admin/configure', false, false)" routerLink="/admin/configure"
          [matTooltip]="t('sidebar_workspace_configuration')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="wrench"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_workspace_configuration')}}
          </ng-container>
        </a>
        <a mat-list-item [class.active]="isRouteActive('admin/license', false, false)" routerLink="/admin/license"
          [matTooltip]="t('sidebar_workspace_licenses')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="key-variant"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_workspace_licenses')}}
          </ng-container>
        </a>
      </mat-nav-list>

      <p class="mat-title menu-title">
        <ng-container *ngIf="!collapsed">{{t('sidebar_project_configuration')}}</ng-container>
      </p>
      <mat-divider></mat-divider>

      <mat-nav-list>
        <a mat-list-item [class.active]="isRouteActive('admin/new', false, false)" routerLink="/admin/new"
          [matTooltip]="t('sidebar_new_project')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="folder-plus"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_new_project')}}
          </ng-container>
        </a>
        <a mat-list-item [class.active]="isRouteActive('admin/open', false, false)" routerLink="/admin/open"
          [matTooltip]="t('sidebar_open_project')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="folder-open"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_open_project')}}
          </ng-container>
        </a>
      </mat-nav-list>
    </ng-container>

    <ng-container *ngSwitchCase="'project'">
      <ng-container *ngFor="let route of routes">
        <p class="mat-title menu-title">
          <ng-container *ngIf="!collapsed">{{t(route.label) }}</ng-container>
        </p>
        <mat-divider></mat-divider>

        <mat-nav-list [class.disabled]="noProjectSelected() || !canManageProject()">
          <ng-container *ngFor="let subRoute of route.items">
            <a mat-list-item [class.active]="isRouteActive(subRoute.url, subRoute.exact)"
              (click)="navigateTo(subRoute.url, subRoute.params)" [matTooltip]="t(subRoute.label)"
              matTooltipPosition="right" [matTooltipDisabled]="!collapsed" [class.disabled]="!canManageProject()">
              <mat-icon [svgIcon]="subRoute.icon || 'play'"></mat-icon>
              <ng-container *ngIf="!collapsed">
                &nbsp;{{t(subRoute.label)}}
              </ng-container>
            </a>
          </ng-container>
        </mat-nav-list>

        <!--
        <a mat-list-item (click)="goToRoute('layout', { type: 'record'})" [routerLinkActive]="'is-active'">Mobile
          Layouts</a>
        <a mat-list-item (click)="goToRoute('layout', { type: 'examination'})" [routerLinkActive]="'is-active'">Lab
          layouts</a>
        -->
      </ng-container>

      <p class="mat-title menu-title">
        <ng-container *ngIf="!collapsed">{{t('sidebar_lab_identification')}}</ng-container>
      </p>
      <mat-divider></mat-divider>

      <mat-nav-list [class.disabled]="noProjectSelected() || !canAccessLab()">
        <a mat-list-item [class.active]="isRouteActive('lab', true)" (click)="navigateTo('lab')"
          [matTooltip]="t('sidebar_lab_results')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="test-tube"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_lab_results')}}
          </ng-container>
        </a>
        <a mat-list-item [class.disabled]="!canAccessProgressReport()" [class.active]="isRouteActive('lab/report')"
          (click)="navigateTo('lab/report')" [matTooltip]="t('sidebar_progress_report')" matTooltipPosition="right"
          [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="chart-timeline"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_progress_report')}}
          </ng-container>
        </a>
      </mat-nav-list>

      <p class="mat-title menu-title">
        <ng-container *ngIf="!collapsed">{{t('sidebar_advanced')}}</ng-container>
      </p>
      <mat-divider></mat-divider>

      <mat-nav-list [class.disabled]="noProjectSelected()">
        <a mat-list-item [class.active]="isRouteActive('export', true)" (click)="navigateTo('export')"
          [matTooltip]="t('sidebar_export_data')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed"
          [class.disabled]="!canAccessExport()">
          <mat-icon svgIcon="database-export"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_export_data')}}
          </ng-container>
        </a>
        <a mat-list-item [class.active]="isRouteActive('datafile', true)" (click)="navigateTo('datafile')"
          [matTooltip]="t('sidebar_data_files')" matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
          <mat-icon svgIcon="file-import"></mat-icon>
          <ng-container *ngIf="!collapsed">
            &nbsp;{{t('sidebar_data_files')}}
          </ng-container>
        </a>
      </mat-nav-list>

      <nav *ngIf="!noProjectSelected()">
        <mat-divider></mat-divider>
        <mat-nav-list>
          <a mat-list-item (click)="closeProject()" [matTooltip]="t('sidebar_close_project')" matTooltipPosition="right"
            [matTooltipDisabled]="!collapsed">
            <mat-icon svgIcon="close"></mat-icon>
            <ng-container *ngIf="!collapsed">
              &nbsp;{{t('sidebar_close_project')}}
            </ng-container>
          </a>
        </mat-nav-list>
      </nav>

      <ng-container *ngIf="tokenService.isAdmin() && !noProjectSelected()">
        <mat-divider></mat-divider>
        <mat-nav-list>
          <a mat-list-item (click)="deleteProject()" [matTooltip]="t('sidebar_delete_project')"
            matTooltipPosition="right" [matTooltipDisabled]="!collapsed">
            <mat-icon svgIcon="delete"></mat-icon>
            <ng-container *ngIf="!collapsed">
              &nbsp;{{t('sidebar_delete_project')}}
            </ng-container>
          </a>
        </mat-nav-list>
      </ng-container>
    </ng-container>
  </div>


  <mat-divider vertical></mat-divider>
  <button mat-button (click)="collapse()" [class.collapsed]="collapsed" class="collapse-button">
    <mat-icon *ngIf="collapsed" svgIcon="chevron-right"></mat-icon>
    <mat-icon *ngIf="!collapsed" svgIcon="chevron-left"></mat-icon>
  </button>
  <mat-divider vertical></mat-divider>
</ng-container>
