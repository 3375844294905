import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '@models';
import { Observable } from 'rxjs';


@Injectable()
export class NewProjectService {
  private _projectUrl = 'api/project';

  constructor(private _http: HttpClient) {}

  createProject(project: Project): Observable<any> {
    return this._http.post<any>(this._projectUrl, project);
  }
}
