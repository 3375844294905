import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ProductGuard implements CanActivate {
  constructor(private _router: Router, @Inject('PRODUCT') private _productName: string) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._productName === next.data.product) {
      return true;
    }
    this._router.navigate(['/admin']);
    return false;
  }
}
