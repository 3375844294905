import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceIdService } from './device-id.service';

@Injectable()
export class DeviceIdInterceptor implements HttpInterceptor {
  constructor(private _deviceIdService: DeviceIdService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;

    if (this._deviceIdService.deviceId) {
      headers = headers.set('VECMAP-Device_id', this._deviceIdService.deviceId);
    }

    const duplicate = req.clone({
      headers: headers
    });
    return next.handle(duplicate);
  }
}
