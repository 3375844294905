import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SharedModule } from '@shared/shared.module';

import { ConsentDialog } from './consent.component';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { TrackingConsentComponent } from './tracking-consent.component';

@NgModule({
  imports: [
    MatStepperModule,
    MatDialogModule,
    SharedModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  exports: [
    TrackingConsentComponent,
  ],
  declarations: [ConsentDialog, PrivacyPolicyComponent, TrackingConsentComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'consent' }],
  entryComponents: [ConsentDialog]
})
export class ConsentModule { }
