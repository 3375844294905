import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthModel } from '@models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TokenService } from './core/token.service';

interface FullAuthResult extends AuthConsent {
  token: string;
}

export interface AuthConsent {
  consent: Consent;
  privacy_policy?: string;
}

export interface Consent {
  privacy_policy: boolean;
  error_reporting: boolean;
  analytics: boolean;
  analytics_contact: boolean;
}

@Injectable()
export class AuthService {
  private _authUrl = 'api/user';

  constructor(private _router: Router, private _http: HttpClient, private _tokenService: TokenService) {}

  login(credentials: AuthModel): Observable<FullAuthResult> {
    return this._http.post(`${this._authUrl}/login`, credentials).pipe(
      tap((authResult: FullAuthResult) => {
      // tap((data)  => {
        this._tokenService.setNewToken(authResult.token);

      // this.log(filename, data),

        // this._userInfoService.setUserInfo(userInfo);
        // return userInfo;
        // return authResult;
      })
    );
  }

  logout() {
    this._tokenService.logout();
    this._router.navigate(['/login']);
  }
}
