import { Injectable } from '@angular/core';
import { getBrowserLang, TranslocoService, translate } from '@ngneat/transloco';
import { AVAILABLE_LANGS } from 'app/app.constants';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

const SELECTED_CULTURE_COOKIE_NAME = 'VECMAP-Culture_Selected';
const WORKSPACE_CULTURE_COOKIE_NAME = 'VECMAP-Culture_Workspace';

@Injectable()
export class CultureService {
  workspaceCulture = new BehaviorSubject<string>('');

  constructor(
    private _translocoService: TranslocoService,
    private _cookieService: CookieService,
    private _toastService: ToastrService
  ) {
    // Read previous cookie or default to device
    this._findResultingCulture(this._cookieService.get(SELECTED_CULTURE_COOKIE_NAME) || 'device');

    this.workspaceCulture
      .pipe(filter(lang => lang !== ''))
      .subscribe({
        next: currentWorkspaceCulture => {
          this._setCookie(WORKSPACE_CULTURE_COOKIE_NAME, currentWorkspaceCulture);

          if (this.getCurrentLanguage() === 'workspace') {
            this._findResultingCulture(this.getCurrentLanguage());
          }
        }
      });
  }

  private _findResultingCulture(language?: string) {
    // Store language for next time
    this._setCookie(SELECTED_CULTURE_COOKIE_NAME, language);

    if (language === 'workspace') { language = this._cookieService.get(WORKSPACE_CULTURE_COOKIE_NAME); }
    if (language === 'device') { language = getBrowserLang(); }

    if (!AVAILABLE_LANGS.map(lang => lang.key).includes(language)) {
      // If language is not supported, we fall back to default language (EN)
      language = this._translocoService.getDefaultLang();
    }

    // Set active language
    this._translocoService.setActiveLang(language);
  }

  changeLanguage(language?: string) {
    this._findResultingCulture(language);
    //TODO Translation = fix english message on first change to FR
    // Notify language changed
    this._toastService.success(translate('language_changed'));
  }

  getCurrentLanguage() {
    return this._cookieService.get(SELECTED_CULTURE_COOKIE_NAME);
  }

  private _setCookie(name, value) {
    this._cookieService.set(name, value, null, '/');
  }
}
