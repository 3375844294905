<mat-toolbar *ngIf="tokenService.token" color="primary">
  <ng-container *transloco="let t; read: 'shell'">
    <button mat-icon-button *ngIf="tokenService.isAdmin()" (click)="returnAdmin()" class="home-button">
      <mat-icon svgIcon="home"></mat-icon>
    </button>
    <ol class="breadcrumb">
      <li *ngFor="let crumb of shellService.breadcrumbs" class="breadcrumb-item">
        <a [routerLink]="[crumb.path]" [queryParams]="crumb.params">
          {{ crumb.translate ? t(crumb.label) : crumb.label }}
        </a>
      </li>
    </ol>

    <span class="toolbar-spacer"></span>
    <button mat-button (click)="logout()">
      <mat-icon svgIcon="logout"></mat-icon> {{t('header_logout_button')}}
    </button>
  </ng-container>
</mat-toolbar>
