import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { isProductionMode } from 'app/utities.config';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AVAILABLE_LANGS } from '../app.constants';


@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string, { scope }) {
    const base = this.http.get(`/assets/i18n/${lang}.json`);

    if (scope) {
      return base;
    }
    return forkJoin([
      base,
      this.http.get(`/assets/i18n/${lang}.vendor.json`)
    ]).pipe(map(([translation, vendor]) => {
      return { ...translation, ...vendor };
    }));
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: AVAILABLE_LANGS.map(lang => lang.key),
        defaultLang: AVAILABLE_LANGS[0].key,
        fallbackLang: AVAILABLE_LANGS[0].key,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: true,
        missingHandler: {
          allowEmpty: true,
          useFallbackTranslation: true
        },
        flatten: {
          aot: isProductionMode()
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule { }
