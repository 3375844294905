import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslocoModule } from '@ngneat/transloco';

import { ActionbarComponent } from './actionbar/actionbar.component';
import { DateFormatPipe } from './date.pipe';
import { ConfirmDialogComponent } from './dialog/confirm-dialog.component';
import { HighlightPipe } from './highlight.pipe';
import { LayoutTypePipe } from './layout_type.pipe';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { PagerComponent } from './pager/pager.component';
import { PermissionPipe } from './permission.pipe';
import { SelectLayoutDialog } from './select-layout/select-layout-dialog';
import { ProjectSettingsFormComponent } from './project-settings-form/project-settings-form.component';
import { FormatBytesPipe } from './size.pipe';
import { SpeciesService } from './species.service';
import { NumberTimesPipe } from './times.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DatetimeFormatPipe } from './datetime.pipe';
import { LeafletMapComponent } from './leaflet-map/leaflet-map.component';
import { SpeciesSelectionDialogComponent } from './species-selection-dialog/species-selection-dialog.component';
import { SpeciesSelectionService } from './species-selection-dialog/species-selection.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LocationFormComponent } from './location-form/location-form.component';
import { MessageComponent } from './message/message.component';
import { SocketIoModule } from 'ngx-socket-io';
import { ApiWebsocket } from './websockets/websocket.service';
import { ProgressLoadingOverlayService } from './loading-spinner/progress-loading-overlay.service';
import { ProgressLoadingComponent } from './loading-spinner/progress-loading.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatRadioModule,
    MatPaginatorModule,
    MatListModule,
    MatProgressBarModule,

    SocketIoModule,

    //Remove
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslocoModule,
    MatAutocompleteModule
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    ActionbarComponent,
    ConfirmDialogComponent,
    SpeciesSelectionDialogComponent,
    LoadingSpinnerComponent,
    PagerComponent,
    LeafletMapComponent,

    HighlightPipe,
    TruncatePipe,
    LayoutTypePipe,
    DateFormatPipe,
    DatetimeFormatPipe,
    NumberTimesPipe,
    PermissionPipe,
    FormatBytesPipe,

    ProjectSettingsFormComponent,
    LocationFormComponent,

    MessageComponent,

    TranslocoModule
  ],
  declarations: [
    // Components
    ActionbarComponent,
    ConfirmDialogComponent,
    SpeciesSelectionDialogComponent,
    LoadingSpinnerComponent,
    PagerComponent,
    LeafletMapComponent,
    LocationFormComponent,
    // Pipes
    HighlightPipe,
    TruncatePipe,
    LayoutTypePipe,
    DateFormatPipe,
    DatetimeFormatPipe,
    NumberTimesPipe,
    PermissionPipe,
    FormatBytesPipe,

    SelectLayoutDialog,
    ProgressLoadingComponent,

    MessageComponent,

    ProjectSettingsFormComponent //TODO: Move this out of here, shouldn't be here!
    // Also remove related modules: Card and input and form field and select and forms
  ],
  providers: [
    SpeciesService,
    DecimalPipe,
    DateFormatPipe,
    DatetimeFormatPipe,
    SpeciesSelectionService,
    ApiWebsocket
  ],
  entryComponents: [ConfirmDialogComponent, SelectLayoutDialog, SpeciesSelectionDialogComponent, ProgressLoadingComponent]
})
export class SharedModule { }
