import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NewProjectService } from './new-project.service';
import { TranslocoService, translate } from '@ngneat/transloco';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'vecmap-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css']
})
export class NewProjectComponent {
  projectSettingsForm: FormGroup = new FormGroup({});

  constructor(private _projectService: NewProjectService, private _router: Router, private _toastr: ToastrService, private translocoservice: TranslocoService) { }

  createProject() {
    if (this.projectSettingsForm.valid) {
      this._projectService.createProject(this.projectSettingsForm.value).subscribe(projectUid => {
        this._toastr.success(translate('project_created_toastr'));
        this._router.navigate(['/project', projectUid.project_id]);
      });
    }
  }
}
